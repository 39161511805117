.input-password {
  box-sizing: border-box; }
  .input-password input {
    box-sizing: border-box;
    display: block;
    margin: 5px 0 10px; }
    .input-password input:focus {
      outline: none; }
  .input-password--wide input {
    width: 100%; }
  .input-password__description {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 25px; }
    .input-password__description li {
      font-size: 16px;
      margin-bottom: 12px;
      margin-left: 20px;
      position: relative;
      color: #696D8C; }
      .input-password__description li:before {
        content: ' ';
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #F2F2F2;
        position: absolute;
        left: -20px;
        top: 4px; }
      .input-password__description li.danger:before {
        background-color: #F79682; }
      .input-password__description li.success:before {
        background-color: #17D499; }
  .input-password__marker {
    display: flex;
    margin-bottom: 15px; }
    .input-password__marker > div {
      background-color: #F2F2F2;
      border-radius: 10px;
      height: 8px;
      width: 33%;
      flex: 1;
      margin: 0 4px; }
      .input-password__marker > div:last-child {
        margin-right: 0; }
      .input-password__marker > div:first-child {
        margin-left: 0; }
      .input-password__marker > div.danger {
        background-color: #F79682; }
      .input-password__marker > div.warning {
        background-color: #F7BC1C; }
      .input-password__marker > div.success {
        background-color: #17D499; }
  .input-password .none input {
    border-color: #F2F2F2; }
  .input-password .danger input {
    border-color: #F79682; }
  .input-password .warning input {
    border-color: #F7BC1C; }
  .input-password .success input {
    border-color: #17D499; }
